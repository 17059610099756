<template>
    <!--begin::Card-->
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <form class="form" @submit.prevent="handleSubmit(onSubmit)" autocomplete="off">
            <div class="card card-custom">
                <!--begin::Header-->
                <div class="card-header">
                    <div class="card-title ">
                        <h3 class="card-label">
                            Sửa nhân viên
                        </h3>
                    </div>
                    <div class="card-toolbar">
                        <router-link
                            class="font-weight-bold font-size-3  btn btn-secondary"
                            :to="{ name: 'user-index' }"
                        ><i class="fas fa-angle-double-left"></i> Quay lại
                        </router-link>
                    </div>
                </div>
                <!--end::Header-->
                <div class="card-body">
                    <div class="row">
                        <h3 class="col-md-12 text-dark font-weight-bold my-2 mr-5">Thông tin cá nhân</h3>
                        <div class="col-md-4 ">
                            <div class="form-group">
                                <label>Họ tên
                                    <span class="text-danger">*</span></label>
                                <ValidationProvider name="Họ tên" rules="required" v-slot="{ errors,classes }">
                                    <input type="text" ref="name" name="name" class="form-control" :class="classes"
                                           placeholder="Họ tên"
                                           v-model=user.name>
                                    <div class="fv-plugins-message-container">
                                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                                            {{ errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="col-md-4 ">
                            <div class="form-group">
                                <label>Email
                                    <span class="text-danger">*</span></label>
                                <ValidationProvider vid="emails" name="Email đăng nhập"
                                                    rules="required"
                                                    v-slot="{ errors,classes }">
                                    <input type="email" class="form-control" placeholder="Email"
                                           :class="classes"
                                           v-model=user.email
                                           name="email"
                                           ref="email"
                                    >
                                    <div class="fv-plugins-message-container">
                                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                                            {{ errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="col-md-4 ">
                            <div class="form-group">
                                <label>Số điện thoại
                                    <span class="text-danger">*</span></label>
                                <ValidationProvider vid="phone" name="Số điện thoại"
                                                    :rules="{ required: true,regex: /^(0[3|5|7|8|9])+([0-9]{8})$/ }"
                                                    v-slot="{ errors,classes }">

                                    <input type="number" v-model="user.phone" :class="classes" class="form-control"
                                           placeholder="Số điện thoại">
                                    <div class="fv-plugins-message-container">
                                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                                            {{ errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="col-md-4 ">
                            <div class="form-group">
                                <label>Giới tính
                                    <span class="text-danger">*</span></label>
                                <multiselect v-model="user.profile.gender" :options="gender"
                                             :searchable="true"
                                             :close-on-select="true"
                                             :show-labels="true" placeholder="Pick a value"
                                ></multiselect>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Ngày sinh
                                    <span class="text-danger">*</span></label>
                                <ValidationProvider vid="profile.birthday" name="Ngày sinh" rules="required"
                                                    v-slot="{ errors,classes }">
                                    <date-picker v-model="user.profile.birthday" format="DD-MM-YYYY"
                                                 valueType="YYYY-MM-DD" :class="classes"></date-picker>
                                    <div class="fv-plugins-message-container">
                                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                                            {{ errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                      <div class="col-md-4 ">
                        <div class="form-group">
                          <label>Số điện thoại liên hệ
                            <span class="text-danger">*</span></label>
                          <ValidationProvider vid="phone_other" name="Số điện thoại liên hệ"
                                              :rules="{regex: /^(0[3|5|7|8|9])+([0-9]{8})$/ }"
                                              v-slot="{ errors,classes }">

                            <input type="number" v-model="user.phone_other" :class="classes" class="form-control"
                                   placeholder="Số điện thoại liên hệ">
                            <div class="fv-plugins-message-container">
                              <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                                {{ errors[0] }}
                              </div>
                            </div>
                          </ValidationProvider>
                        </div>
                      </div>
                        <div class="col-md-4 ">
                            <div class="form-group">
                                <label>Địa chỉ
                                    <span class="text-danger"></span></label>
                                <textarea placeholder="Chọn địa chỉ" class="form-control" rows="3"
                                          v-model="user.profile.address"></textarea>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>
                                    <span class="text-danger"></span></label>
                                <div class="col-9 col-form-label">
                                    <div class="checkbox-inline">
                                        <label class="checkbox checkbox-success">
                                            <input type="checkbox" name="Checkboxes5"
                                                   v-model="user.profile.status_verify">
                                            <span></span>Xác minh danh tính</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-for="(item, index) in user.user_profile_account_type" :key="index">
                        <user-item
                            @checkCaption="checkCaption"
                            @removeForm="removeForm"
                            :data="item"
                            :parent="user?.user_boss"
                            :index="index"
                        >
                        </user-item>
                        <div class="col-md-12 text-danger m-2 mb-4">{{ messageDuplicate }}</div>
                    </div>
                    <div class="col-md-12 p-0 mb-4">
                        <button @click="addTotalForm" class="btn btn-success mr-2 btn-sm">Thêm công việc</button>
                    </div>
                  <!--            Thông tin Tài khoản ngân hàng-->
                  <div class="row">
                    <h3 class="col-md-12 text-dark font-weight-bold my-2 mr-5">Thông tin Tài khoản ngân hàng</h3>
                    <div class="col-md-4 ">
                      <div class="form-group">
                        <label>Chủ tài khoản</label>
                        <input type="text" ref="name" name="name" class="form-control"
                               placeholder="Chủ tài khoản"
                               v-model="bank_accounts.owner_name" @input="formatInput">
                      </div>
                    </div>
                    <div class="col-md-4 ">
                      <div class="form-group">
                        <label>Chọn ngân hàng</label>
                        <el-select v-model="bank_accounts.ngan_hang" @change="changeBank">
                          <el-option v-for="(item, index) in banks"
                                     :key="index"
                                     :label="item.name" :value="item.id"
                          >
                            <span>{{ item.name }}</span>
                          </el-option>
                        </el-select>
                      </div>
                    </div>
                    <div class="col-md-4 " v-if="hideProvince">
                      <div class="form-group">
                        <label>Tình thành</label>
                        <el-select v-model="bank_accounts.province" @change="changeProvince">
                          <el-option v-for="(item, index) in provinces"
                                     :key="index"
                                     :label="item" :value="item"
                          >
                            <span>{{ item }}</span>
                          </el-option>
                        </el-select>
                      </div>
                    </div>
                    <div class="col-md-4 " v-if="hideProvince">
                      <div class="form-group">
                        <label>Chi nhánh</label>
                        <div>
                          <el-select v-model="bank_accounts.chi_nhanh">
                            <el-option v-for="(item, index) in branchs"
                                       :key="index"
                                       :label="item"
                                       :value="item"
                            >
                              <span>{{ item }}</span>
                            </el-option>
                          </el-select>
                        </div>

                      </div>
                    </div>
                    <div class="col-md-4 ">
                      <div class="form-group">
                        <label>Số tài khoản</label>
                        <input type="text" ref="name" name="name" class="form-control"
                               placeholder="Số tài khoản"
                               v-model=bank_accounts.stk>
                      </div>
                    </div>
                  </div>
                    <div class="row">
                        <h3 class="col-md-12 text-dark font-weight-bold my-2 mr-5">Thông tin tài khoản</h3>
                        <div class="col-md-4 ">
                            <div class="form-group">
                                <label>Email đăng nhập</label>
                                <ValidationProvider vid="email" name="Email" rules="required|email"
                                                    v-slot="{ errors,classes }">
                                    <input type="email" disabled class="form-control" placeholder="Email"
                                           :class="classes"
                                           v-model=user.email>
                                    <div class="fv-plugins-message-container">
                                        <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                                            {{ errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Phân quyền trang admin
                                    <span class="text-danger"></span></label>
                                <el-select v-model="user.backend_role_id" clearable filterable
                                           class="w-100" placeholder="Chọn quyền"
                                           ref="backend_role_id">
                                    <el-option
                                        v-for="(item, key) in backend_roles"
                                        :label="item.name"
                                        :value="item.id"
                                        :key="key"
                                    >
                                        <span style="float: left">{{ item.name }}</span>
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Telegram ID
                                    <span class="text-danger"></span></label>
                                <input type="number" name="name" class="form-control"
                                       placeholder="Telegram ID"
                                       v-model=user.profile.telegram_id>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-header">
                    <div class="card-title align-items-start flex-column">
                        <button type="submit" class="btn btn-success mr-2" :disabled="is_disable">
                            Sửa
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </ValidationObserver>
    <!--end::Card-->
</template>
<script>
import {mapGetters} from "vuex";
import {provinces} from "@/assets/js/components/provinces";
import {banks} from "@/assets/js/components/banks";
import {branchs} from "@/assets/js/components/branchs";
import {
    CHANGE_PASSWORD,
    GET_USER_BY_ID,
    SEARCH_BY_MA_CAP_TREN,
    UPDATE_STAFF
} from "@/core/services/store/user/users.module";
import Multiselect from 'vue-multiselect'
import BankAccountEdit from "./BankAccountEdit";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import Select2Ajax from "../../content/Select2Ajax";
import Select2 from "../../content/Select2";
import {GET_LIST_PERMISSION} from "@/core/services/ajaxUrl";
import Swal from "sweetalert2";
import {GET_ACCOUNT_TYPE, GET_POSITIONS, GET_ROLES} from "@/core/services/store/user/account-type.module";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {GET_AREAS} from "@/core/services/store/center/area.module";
import {GET_BRANCHES} from "@/core/services/store/center/branch.module";
import {
    BRANCH,
    CENTER_ASSIGN,
    GDKV,
    GDVP,
    QLHV,
    SALE_EXPERT,
    SALE_INTERN,
    SALE_LEADER,
    SALE_MEMBER
} from "@/core/option/accountTypeOption";
import {GET_BUSINESS_LEVEL} from "@/core/services/store/business/level.module";
import {mechanisms, mechanisms2} from "@/core/option/mechanism";
import {MT01, MT02} from "@/core/option/area";
import {GET_ROLE_DEPARTMENT_BY_BLOCK_ID} from "@/core/services/store/department/department.module";
import {BLOCK, GET_DEPARTMENT, GET_ROLE_DEPARTMENT_BY_TYPE} from "@/core/services/store/department/department.module";
import UserItem from "./Component/UserItem";

export default {
    name: "UserEdit",
    components: {
        BankAccountEdit,
        Multiselect,
        DatePicker,
        Select2,
        Select2Ajax,
        UserItem
    },
    data() {
        return {
            tab_active1: true,
            tab_active2: false,
            show_active_1: true,
            show_active_2: false,
            prevRoute: null,
            user: {
                uuid: '',
                name: '',
                phone: "",
                phone_other:"",
                email: "",
                email_verified_at: "",
                status: true,
                parent_id: "",
                remember_token: "",
                password: "",
                confirm_password: "",
                positions: [],
                profile: {
                    uuid: "",
                    ma_gioi_thieu: "",
                    gioithieu_id: "",
                    gender: 'Nam',
                    address: "",
                    birthday: "",
                    start_to_work: "",
                    loai_tai_khoan: "",
                    chi_nhanh_id: "",
                    trung_tam_id: "",
                    van_phong_id: "",
                    xac_thuc_sdt: "",
                    thoi_gian_nghi_viec: "",
                    account_type_id: '',
                    department_id: '',
                    status_verify: false,
                    telegram_id: ''
                },
                user_profile_account_type: [],
                backend_role_id: '',
            },
            bank_accounts: {
                owner_name: "",
                ngan_hang: "",
                province: "",
                chi_nhanh: "",
                stk: "",
                enable_branch_bank: false,
                branch_affter_select_province: [],
                is_default: true
            },
            /*Data default*/
            isLoadingBoss: false,
            keyword_search_boss_code: '',
            status: {
                0: 'Không hoạt động',
                1: 'Đang hoạt động',
                2: "Nghỉ việc"
            },
            gender: [
                'Nam', 'Nữ', 'Khác'
            ],
            boss_type_account: {
                1: 'Sale Leader',
                2: 'Sale Member'
            },
            branches: [],
            positions: [],
            provinces: [],
            banks: [],
            backend_roles: [],
            maCapTren: [],
            branchs: {}, // Branh của tài khoản ngân hàng
            areas: [],
            centers: [],
            /*Cache */
            cacheAccountType: [],
            cachePosition: [],
            cacheBank: [],
            accountType: [],
            components: [
                'Autocompletes', 'Comboboxes', 'Forms', 'Inputs', 'Overflow Buttons', 'Selects', 'Selection Controls', 'Sliders', 'Textareas', 'Text Fields',
            ],
            showSelectBoss: false,
            is_show_branch: false,
            showTrungTam: false,
            is_disable: false,
            search_loading: false,
            users: [],
            businessLevels: [],
            showBusinessLevel: false,
            isBackOffice: false,
            mechanisms: [],
            is_show_mechanism: false,
            departments: {},
            part: {},
            listBlock: {},
            checkShowDepartment: false,
            show: false,
            messageDuplicate: '',
            hideProvince: false
        };
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRoute = from
        })
    }
    ,
    computed: {
        getPermissionUrl: function () {
            return GET_LIST_PERMISSION
        },
        ...mapGetters(["currentUserAccountInfo", 'roles'])
    },
    mounted() {
        this.getMechanisms();
        this.getBlock();
    },
    async created() {
        this.getRoles();
        await this.getUserById();
        await this.checkShowBusinessLevel();
        await this.checkShowMechanism();
        await this.setDefault();
        await this.getAreas();
        await this.getBranches();
        await this.getPositions();
        this.selectMechanismByArea();
        await this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Quản lý nhân sự", route: 'user-index'},
            {title: "Sửa nhân viên"}
        ]);
        this.provinces = provinces
        this.provinces = provinces
        this.banks = banks
    },
    methods: {
        checkShowMechanism() {
            this.user.profile.salary_mechanism_id = this.user.profile.salary_mechanism_id > 0 ? this.user.profile.salary_mechanism_id : '';
            this.is_show_mechanism = [SALE_MEMBER, SALE_INTERN].includes(this.user.profile.account_type_id)
        },
        setDefault() {
            if (this.user.user_boss) {
                this.users.push(this.user.user_boss);
            }
        },
        getRoles() {
            this.$store.dispatch(GET_ROLES, {
                limit: 1000
            }).then((res) => {
                this.backend_roles = res.data.data.filter(value => value.guard_name === 'backend');
            });
        },
        async getUserById() {
            let id = parseInt(this.$route.params.id);
            if (id) {
                await this.$store.dispatch(GET_USER_BY_ID, id).then((res) => {
                    if (!res.error) {
                        let user = res.data
                        if (Object.values(res.data.bank_accounts).length) {
                            user.bank_accounts = res.data.bank_accounts[0];
                            if (user.bank_accounts.province || user.bank_accounts.chi_nhanh) {
                                this.hideProvince = true;
                            }
                            this.branchs = user.bank_accounts.province ? branchs[user.bank_accounts.province] ?? '' : '';
                        }

                        user.user_profile_account_type.forEach((d) => {
                            d.captain = !!d.type;
                        });
                        this.bank_accounts = user.bank_accounts;
                        this.user = user;
                    }
                });
            }
        },
        async getBranches() {
            await this.$store.dispatch(GET_BRANCHES, {
                limit: 10000,
                status: 1,
                area_id: this.user.profile.khuvuc_id,
            }).then((res) => {
                if (!res.error) {
                    this.branches = res.data.data
                }
            }).catch((e) => {
                console.log(e, 'error')
            });
        },
        /*Thêm account*/
        async getAreas() {
            await this.$store.dispatch(GET_AREAS, {
                limit: 10000,
            }).then((res) => {
                if (!res.error) {
                    this.areas = res.data.data
                }
            });
        },
        addAccount() {
            this.user.bank_accounts.push({
                enable_branch_bank: false,
                owner_name: "",
                ngan_hang: "",
                chi_nhanh: "",
                stk: "",
                loai_tai_khoan: "",
                is_default: false,
            })
        },
        /*Xóa account*/
        minusAccount(index) {
            Swal.fire({
                title: "Bạn chắc chắn muốn xóa?",
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: "Đồng ý",
                cancelButtonText: "Không",
            }).then((result) => {
                if (result.isConfirmed) {
                    if (index > 0) {
                        this.user.bank_accounts.splice(index, 1)
                    }
                }
            });
        },
        /*Nếu là ngân hàng agribank thì cho chọn thành phố vào chi nhánh*/
        updateStateCenter(obj) {
            this.user.profile.trung_tam_id = obj.id
        },
        updateStateKhuvuc() {
            this.user.profile.salary_mechanism_id = '';
            this.mechanisms = [];
            this.user.profile.chi_nhanh_id = '';
            this.branches = [];
            this.getBranches();
            this.selectMechanismByArea();
        },
        updateStateAccountType(obj) {
            this.updateShowSelectBoss(obj)
            this.user.profile.account_type_id = obj.id
        },
        updateStatePositions($event) {
            this.user.user_positions = []
            $event.map((value) => {
                this.positions.map((v) => {
                    if (value.position == v.position) {
                        this.user.user_positions.push({
                            position: v.id
                        })
                    }
                })
            })
        },
        updateStateMacaptren(obj) {
            this.user.parent_id = obj.id
        },
        updateStateBank(obj) {
            this.user.bank_accounts.ngan_hang = obj.id
        },
        async getAccountType(block_id) {
            await this.$store.dispatch(GET_ACCOUNT_TYPE, {
                type_department: block_id == 21 || block_id == 19 ? 1 : 2,
                limit: 1000,
            }).then((res) => {
                if (!res.error) {
                    this.accountType = res.data.data
                }
            });
        },
        getPositions() {
            this.$store.dispatch(GET_POSITIONS, {}).then((res) => {
                if (!res.error) {
                    this.positions = res.data
                }
            });
        },
        handleAccountType(id) {
            this.updateShowSelectBoss(id)
            this.checkShowCenterAndBranch(id)
            this.checkShowBusinessLevel(id);
            this.checkShowMechanism();
        },
        searchUserByBossCode(query) {
            this.search_loading = true;
            if (query.length > 0) {
                this.$store.dispatch(SEARCH_BY_MA_CAP_TREN, {
                    search_boss: query,
                    is_staff: 1,
                    limit: 100,
                }).then((res) => {
                    if (!res.error) {
                        this.users = res.data.data;
                        this.search_loading = false;
                    }
                }).catch(() => {
                    this.search_loading = false;
                });
            } else {
                this.search_loading = false;
            }
        },
        checkShowCenterAndBranch(id) {
            //12: QLHV, 5: GDVP
            if (id === SALE_LEADER || id === SALE_EXPERT || id === SALE_MEMBER || id === SALE_INTERN || id === GDVP || id === QLHV) {
                this.is_show_branch = true
            } else {
                this.is_show_branch = false
            }
            if (id === CENTER_ASSIGN || id === BRANCH) {
                this.showTrungTam = true;
            } else {
                this.showTrungTam = false;
            }
        },
        checkDuplicateDepartment() {
            var values = this.user.user_profile_account_type;
            var block_id = values.map(function (item) {
                return item.block_id
            });
            var department_id = values.map(function (item) {
                return item.department_id
            });
            var part_id = values.map(function (item) {
                return item.part_id
            });

            var isDuplicateBlockId = block_id.some(function (item, idx) {
                return block_id.indexOf(item) != idx
            });
            var isDuplicateDepartmentId = department_id.some(function (item, idx) {
                return department_id.indexOf(item) != idx && part_id.indexOf(item) != '' && idx != ''
            });
            var isDuplicatePartId = part_id.some(function (item, idx) {
                return part_id.indexOf(item) != idx && part_id.indexOf(item) == 0 && item != ''
            });

            return [isDuplicateBlockId, isDuplicateDepartmentId, isDuplicatePartId].includes(false);
        },
        onSubmit() {
            this.messageDuplicate = '';
            if (!this.checkDuplicateDepartment()) {
                this.messageDuplicate = 'Thông tin bị trùng lặp phòng ban !';
                return;
            }
            this.convertDataDepartment();
            this.is_disable = true;
            let payload = this.user;
            payload.bank_accounts = {
                owner_name: this.bank_accounts.owner_name,
                ngan_hang: this.bank_accounts.ngan_hang,
                province: this.bank_accounts.province,
                chi_nhanh: this.bank_accounts.chi_nhanh,
                stk: this.bank_accounts.stk,
                enable_branch_bank: false,
                branch_affter_select_province: [],
                is_default: true
            };
            this.$store
                .dispatch(UPDATE_STAFF, payload)
                .then((res) => {
                    this.$router.push({name: 'user-index'}).then(() => {
                        this.$bvToast.toast(res.message, {
                            title: 'Sửa nhân viên',
                            variant: 'success',
                            solid: true
                        });
                    })
                }).catch((e) => {
                if (e.data?.data?.message_validate_form) {
                    this.$refs.form.setErrors(e.data.data.message_validate_form);
                }
                if (e.data?.message) {
                    this.$bvToast.toast('Tạo nhân viên thất bại', {
                        title: 'Tạo nhân viên',
                        variant: 'danger',
                        solid: true
                    });
                }
            }).finally(() => {
                this.is_disable = false;
            });
        },
        handleCache(user) {
            if (user.profile != null && user.profile.account_type != null) {
                this.updateShowSelectBoss(user.profile.account_type)
            }
            this.cacheAccountType = user.profile.account_type
            user.user_positions.map((value) => {
                this.positions.map((v) => {
                    if (value.position == v.id) {
                        value.position = v.position
                    }
                })
            })
            this.cachePosition = user.user_positions
        },
        activeTab(n) {
            if (n == 1) {
                this.tab_active1 = true
                this.tab_active2 = false
                this.show_active_1 = true
                this.show_active_2 = false
            } else {
                this.tab_active1 = false
                this.tab_active2 = true
                this.show_active_1 = false
                this.show_active_2 = true
            }
        },
        updateShowSelectBoss(obj) {
            this.showSelectBoss = obj.id === 1 || obj.id === 2 || obj.id === 3 || obj.id === 4;
        },
        changePassword() {
            let id = this.$route.params.id;

            this.$store.dispatch(CHANGE_PASSWORD, {
                id: id,
                new_password: this.user.password,
                confirm_password: this.user.confirm_password,
            }).then((res) => {
                if (!res.error) {
                    this.$router.push({name: "user-index"}).then(() => {
                        this.$bvToast.toast(res.message, {
                            title: 'Đổi mật khẩu',
                            variant: 'success',
                            solid: true
                        });
                    })
                } else {
                    this.$bvToast.toast(res.message, {
                        title: 'Đổi mật khẩu',
                        variant: 'danger',
                        solid: true
                    });
                }
            }).catch((e) => {
                if (e.data.message.password) {
                    this.$bvToast.toast(e.data.message.password, {
                        title: 'Đổi mật khẩu',
                        variant: 'danger',
                        solid: true
                    });
                    this.$refs.form.setErrors(e.data.message.password);
                }
            });
        },
        getBusinessLevels() {
            this.$store.dispatch(GET_BUSINESS_LEVEL, {
                limit: 10000,
                status: 1,
                account_type_id: this.user.profile.account_type_id
            }).then((res) => {
                if (!res.error) {
                    this.businessLevels = res.data.data
                }
            }).catch((e) => {
                console.log(e, 'error')
            });
        },
        checkShowBusinessLevel() {
            this.showBusinessLevel = false;
            if ([GDVP, GDKV, SALE_LEADER, SALE_MEMBER].includes(this.user.profile.account_type_id)) {
                this.getBusinessLevels();
                this.showBusinessLevel = true;
            }
        },
        selectMechanismByArea() {
            let area_id = this.user.profile.khuvuc_id;

            if (!area_id) {
                return;
            }

            this.is_show_mechanism = true;
            if ([9, 13].includes(area_id)) {
                this.mechanisms = mechanisms;
                return;
            }
            this.mechanisms = mechanisms2;
        },
        getMechanisms() {
            let area_id = this.user.profile.khuvuc_id;
            if ([MT01, MT02].includes(area_id)) {
                this.mechanisms = mechanisms;
                return;
            }
            this.mechanisms = mechanisms2;
        },
        changeListDepartment(block_id) {
            this.show = true;
            if (block_id == 21 || block_id == 19) {
                this.checkShowDepartment = true;
            } else {
                this.checkShowDepartment = false;
            }
            this.$store.dispatch(GET_DEPARTMENT, {parent_id: block_id}).then((res) => {
                if (!res.error) {
                    this.departments = res.data
                    this.getAccountType(block_id);
                    this.changeListPart(block_id);
                }
            }).catch((e) => {
                console.log(e, 'error')
            });
        },
        getDepartmentByBlockId(block_id) {
            this.$store.dispatch(GET_ROLE_DEPARTMENT_BY_BLOCK_ID, block_id).then((res) => {
                if (!res.error) {
                    this.accountType = res.data
                    this.checkShowDepartment = true;
                }
            }).catch((e) => {
                console.log(e, 'error')
            });
        },
        changeListPart(department_id) {
            if (department_id != this.user.department_id) {
                this.user.part_id = '';
            }
            this.$store.dispatch(GET_DEPARTMENT, {parent_id: department_id, type_block_part: true}).then((res) => {
                if (!res.error) {
                    this.part = res.data
                }
            }).catch((e) => {
                console.log(e, 'error')
            });
        },
        getBlock() {
            this.$store.dispatch(GET_ROLE_DEPARTMENT_BY_TYPE, {type: BLOCK}).then((res) => {
                if (!res.error) {
                    this.listBlock = res.data
                }
            }).catch((e) => {
                console.log(e, 'error')
            });
        },
        getListDepartment(block_id) {
            if (block_id == 21 || block_id == 19) {
                this.checkShowDepartment = true;
            } else {
                this.checkShowDepartment = false;
            }
            this.$store.dispatch(GET_DEPARTMENT, {parent_id: block_id}).then((res) => {
                if (!res.error) {
                    this.departments = res.data
                    this.getAccountType(block_id);
                }
            }).catch((e) => {
                console.log(e, 'error')
            });
        },
        getListPart(department_id) {
            this.$store.dispatch(GET_DEPARTMENT, {parent_id: department_id}).then((res) => {
                if (!res.error) {
                    this.part = res.data
                }
            }).catch((e) => {
                console.log(e, 'error')
            });
        },
        checkCaption(even_index, value_cap) {
            let items = this.user.user_profile_account_type.filter((value) => value != value_cap && value.captain == true);
            if (items) {
                items.forEach((d) => {
                    d.captain = false;
                });
            }
        },
        addTotalForm() {
            this.user.user_profile_account_type.push({
                block_id: '',
                cachePosition: [],
                salary_mechanism_id: '',
                khuvuc_id: '',
                chi_nhanh_id: '',
                department_id: '',
                part_id: '',
                account_type_id: '',
                parent_id: '',
                start_to_work: '',
                captain: false
            })
        },
        removeForm(index) {
            const array = this.user.user_profile_account_type;
            if (Object.values(this.user.user_profile_account_type).length == 1) {
                this.$bvToast.toast('Không thể bỏ thông tin nhân viên', {
                    title: 'Không thể bỏ thông tin nhân viên',
                    variant: 'danger',
                    solid: true
                });
                return;
            }

            if (index > -1) {
                array.splice(index, 1);
            }
        },
        convertDataDepartment() {
            this.user.user_profile_account_type.forEach((d) => {
                d.department_id_prop = d.part_id ? d.part_id : (d.department_id ? d.department_id : (d.block_id ? d.block_id : ''));
            });
        },
        changeBank() {
            this.hideProvince = false;
            this.user.bank_accounts.province = '';
            this.user.bank_accounts.chi_nhanh = '';
            if (this.user.bank_accounts.ngan_hang === 'Agribank') {
                this.hideProvince = true;
                this.branchs = {};
            }
        },
        changeProvince() {
            this.branchs = branchs[this.user.bank_accounts.province];
            this.user.bank_accounts.chi_nhanh = '';
        },
        removeAccents(str) {
            return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        },
        formatInput() {
            // Chuyển đổi chữ thường sang chữ hoa và loại bỏ dấu
            this.user.bank_accounts.owner_name = this.removeAccents(this.user.bank_accounts.owner_name.toUpperCase());
        }
    },
};
</script>
<style scoped>
.mx-datepicker {
    width: 100% !important;
}

.add-bank-account:hover i, .add-bank-account:hover .text-muted {
    color: #1BC5BD !important;
}

.el-select {
    width: 100%;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css">
</style>

